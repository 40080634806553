<template>
  <div>
    <custom-snackbar
        ref="snackbar"
    />

    <!-- Formulário -->
    <v-form ref="form" @submit.prevent="handleFormSubmit">
      <v-row>
        <v-col class="col-12 pb-2">
          <v-card >
            <v-card-title>
              Solicitante
            </v-card-title>
            <v-card-text>
              <v-row class="mt-2">
                <v-col class="py-1 col-4">
                  <EmpresasSelect
                      obrigatorio
                      v-model="form.empresaId"
                  />
                </v-col>

                <v-col class="py-1 col-4">
                  <ProducoesSelect
                      obrigatorio
                      administrativo
                      :empresa="form.empresaId"
                      v-model="form.producao"
                      @change="setProducao"
                      return-object
                  />
                </v-col>
                <v-col class="py-1 col-4">
                  <v-text-field
                      dense
                      outlined
                      :rules="[(v) => !!v || 'Obrigatório']"
                      persistent-placeholder
                      label="Solicitante"
                      v-model="form.solicitante"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="col-12 col-sm-6 pb-2">
          <v-card>
            <v-card-title>
              <div class="d-flex justify-content-between w-100">
                <span>Origem</span>
                <v-btn
                    outlined
                    small
                    color="primary"
                    class="ml-2"
                    @click="newOrigem = !newOrigem"
                >{{ newOrigem? 'Selecionar': 'Novo' }}</v-btn>
              </div>
            </v-card-title>
            <v-card-text>
              <v-row class="mt-2" v-if="!newOrigem">
                <v-col>
                  <EnderecosSelect
                    name="select-enderecos-origem"
                    v-model="form.origemId"
                    obrigatorio
                  />
                </v-col>
              </v-row>
              <v-row class="mt-2" v-if="newOrigem">
                <v-col class="col-6">
                  <v-text-field
                      dense
                      outlined
                      :rules="[(v) =>!!v || 'Obrigatório']"
                      persistent-placeholder
                      label="Descrição do endereço"
                      v-model="formOrigem.descricao"
                  />
                </v-col>
                <v-col class="col-6">
                  <v-text-field
                      dense
                      outlined
                      :rules="[(v) =>!!v || 'Obrigatório']"
                      persistent-placeholder
                      v-mask="'#####-###'"
                      label="Cep"
                      v-model="formOrigem.cep"
                  />
                </v-col>
                <v-col class="col-8">
                  <v-text-field
                      dense
                      outlined
                      :rules="[(v) =>!!v || 'Obrigatório']"
                      persistent-placeholder
                      label="Logradouro"
                      v-model="formOrigem.logradouro"
                  />
                </v-col>
                <v-col class="col-4">
                  <v-text-field
                      dense
                      outlined
                      :rules="[(v) =>!!v || 'Obrigatório']"
                      persistent-placeholder
                      label="Número"
                      v-model="formOrigem.numero"
                  />
                </v-col>
                <v-col class="col-6">
                  <v-text-field
                      dense
                      outlined
                      persistent-placeholder
                      label="Complemento"
                      v-model="formOrigem.complemento"
                  />
                </v-col>
                <v-col class="col-6">
                  <v-text-field
                      dense
                      outlined
                      :rules="[(v) =>!!v || 'Obrigatório']"
                      persistent-placeholder
                      label="Bairro"
                      v-model="formOrigem.bairro"
                  />
                </v-col>
                <v-col class="col-6">
                  <CidadeSelect v-model="formOrigem.cidade" :estado="formOrigem.uf" :readonly="!formOrigem.uf" item-value="nome"/>
                </v-col>
                <v-col class="col-6">
                  <EstadoSelect v-model="formOrigem.uf" item-value="sigla"/>
                </v-col>
                <v-col class="col-12">
                  <CoordenadasInput v-model="formOrigem.coordenadas" editable removable/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="col-12 col-sm-6 pb-2">
          <v-card>
            <v-card-title>
              <div class="d-flex justify-content-between w-100">
                <span>Destino</span>
                <v-btn
                    outlined
                    small
                    color="primary"
                    class="ml-2"
                    @click="newDestino = !newDestino"
                >{{ newDestino? 'Selecionar': 'Novo' }}</v-btn>
              </div>
            </v-card-title>
            <v-card-text>
              <v-row class="mt-2" v-if="!newDestino">
                <v-col>
                  <EnderecosSelect
                    name="select-enderecos-destino"
                    v-model="form.destinoId"
                    obrigatorio
                  />
                </v-col>
              </v-row>
              <v-row class="mt-2" v-if="newDestino">
                <v-col class="col-6">
                  <v-text-field
                      dense
                      outlined
                      :rules="[(v) =>!!v || 'Obrigatório']"
                      persistent-placeholder
                      label="Descrição do endereço"
                      v-model="formDestino.descricao"
                  />
                </v-col>
                <v-col class="col-6">
                  <v-text-field
                      dense
                      outlined
                      :rules="[(v) =>!!v || 'Obrigatório']"
                      persistent-placeholder
                      v-mask="'#####-###'"
                      label="Cep"
                      v-model="formDestino.cep"
                  />
                </v-col>
                <v-col class="col-8">
                  <v-text-field
                      dense
                      outlined
                      :rules="[(v) =>!!v || 'Obrigatório']"
                      persistent-placeholder
                      label="Logradouro"
                      v-model="formDestino.logradouro"
                  />
                </v-col>
                <v-col class="col-4">
                  <v-text-field
                      dense
                      outlined
                      :rules="[(v) =>!!v || 'Obrigatório']"
                      persistent-placeholder
                      label="Número"
                      v-model="formDestino.numero"
                  />
                </v-col>
                <v-col class="col-6">
                  <v-text-field
                      dense
                      outlined
                      persistent-placeholder
                      label="Complemento"
                      v-model="formDestino.complemento"
                  />
                </v-col>
                <v-col class="col-6">
                  <v-text-field
                      dense
                      outlined
                      :rules="[(v) =>!!v || 'Obrigatório']"
                      persistent-placeholder
                      label="Bairro"
                      v-model="formDestino.bairro"
                  />
                </v-col>
                <v-col class="col-6">
                  <CidadeSelect v-model="formDestino.cidade" :estado="formDestino.uf" :readonly="!formDestino.uf" return-object/>
                </v-col>
                <v-col class="col-6">
                  <EstadoSelect v-model="formDestino.uf" return-object/>
                </v-col>
                <v-col class="col-12">
                  <CoordenadasInput v-model="formDestino.coordenadas" editable removable/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="col-12 pb-2">
          <v-card >
            <v-card-title>
              Dados da coleta
            </v-card-title>
            <v-card-text>
              <v-row class="mt-2">
                <v-col class="py-1 col-3">
                  <v-row>
                    <v-col>
                      <v-select
                          dense
                          outlined
                          label="Tipo de Produto"
                          item-text="nome"
                          item-value="id"
                          v-model="form.tipoProduto"
                          :items="tipoProdutoOptions"
                          :rules="[(v) => (v !== null && v >= 0) || 'Obrigatório']"
                      />
                    </v-col>
                    <v-col v-if="form.tipoProduto === 3">
                      <v-text-field
                          v-model="form.tipoProdutoOutro"
                          label="Outro tipo de Produto"
                          outlined
                          dense
                          small-chips
                          show-size
                          :rules="[(v) => !!v || 'Obrigatório']"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col class="py-1 col-3">
                  <v-select
                      dense
                      outlined
                      label="Unidade de medida"
                      item-text="nome"
                      item-value="id"
                      v-model="form.unidadeMedida"
                      :items="unidadeMedidaOptions"
                      :rules="[(v) => !!v || 'Obrigatório']"
                  />
                </v-col>

                <v-col class="py-1 col-3">
                  <v-text-field
                      v-model="form.pesoUnitario"
                      label="Peso Unitario (Kg)"
                      type="number"
                      max="10000"
                      min="0"
                      step="0.01"
                      outlined
                      dense
                      small-chips
                      show-size
                      :rules="[(v) => !!v || 'Obrigatório']"
                  />
                </v-col>

                <v-col class="py-1 col-3">
                  <v-text-field
                      v-model="form.quantidade"
                      label="Quantidade"
                      type="number"
                      max="100"
                      min="0"
                      step="1"
                      outlined
                      dense
                      small-chips
                      show-size
                      :rules="[(v) => !!v || 'Obrigatório']"
                  />
                </v-col>

                <v-col class="py-1 col-2">
                  <v-text-field
                      v-model="form.altura"
                      label="Altura (metros)"
                      type="number"
                      max="100"
                      min="0"
                      step="0.01"
                      outlined
                      dense
                      small-chips
                      show-size
                      :rules="[(v) => form.unidadeMedida !== 'UN' || !!v || 'Obrigatório']"
                  />
                </v-col>

                <v-col class="py-1 col-2">
                  <v-text-field
                      v-model="form.largura"
                      label="Largura (metros)"
                      type="number"
                      max="100"
                      min="0"
                      step="0.01"
                      outlined
                      dense
                      small-chips
                      show-size
                      :rules="[(v) => form.unidadeMedida !== 'UN' || !!v || 'Obrigatório']"
                  />
                </v-col>

                <v-col class="py-1 col-2">
                  <v-text-field
                      v-model="form.comprimento"
                      label="Comprimento (metros)"
                      type="number"
                      max="100"
                      min="0"
                      step="0.01"
                      outlined
                      dense
                      small-chips
                      show-size
                      :rules="[(v) => form.unidadeMedida !== 'UN' || !!v || 'Obrigatório']"
                  />
                </v-col>

                <v-col class="py-1 col-2">
                  <v-text-field
                      v-model="form.numeroNF"
                      label="Nota Fiscal"
                      type="number"
                      max="100000000"
                      min="0"
                      step="1"
                      outlined
                      dense
                      small-chips
                      show-size
                  />
                </v-col>

                <v-col class="py-1 col-2">
                  <v-text-field
                      v-model="form.valorNF"
                      label="Valor Nota Fiscal"
                      type="number"
                      max="100"
                      min="0"
                      step="0.01"
                      outlined
                      dense
                      small-chips
                      show-size
                  />
                </v-col>

                <v-col class="py-1 col-2">
                  <v-text-field
                      v-model="form.ordemCompra"
                      label="Ordem de Compra"
                      type="number"
                      max="100000000"
                      min="0"
                      step="1"
                      outlined
                      dense
                      small-chips
                      show-size
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="col-12 pb-2">
          <v-card >
            <v-card-title>
              Dados de entrega
            </v-card-title>
            <v-card-text>
              <v-row class="mt-2">
                <v-col class="py-1 col-6">
                  <EmpresasSelect
                      label="Empresa alocada os custos"
                      v-model="form.custoEmpresaId"
                  />
                </v-col>

                <v-col class="py-1 col-6">
                  <ProducoesSelect
                      label="Produção alocada os custos"
                      administrativo
                      :empresa="form.custoEmpresaId"
                      v-model="form.custoProducaoId"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col class="col-2">
                  <v-switch
                      label="Contratação direta?"
                      v-model="form.contratacaoDireta"
                      outlined
                      dense
                      small-chips
                      show-size/>
                </v-col>
                <v-col class="py-1 col-3">
                  <DatePicker
                      label="Data máxima de entrega"
                      :min="$date.getISOToday()"
                      v-model="form.dataMaximaEntrega"
                      :rules="[(v) => !!v || 'Obrigatório']"
                  />
                </v-col>
                <v-col class="py-1 col-2">
                  <v-text-field
                      v-model="form.valorFreteFornecedor"
                      label="Valor Frete Fornecedor"
                      type="number"
                      max="100"
                      min="0"
                      step="0.01"
                      outlined
                      dense
                      small-chips
                      show-size
                  />
                </v-col>
                <v-col class="py-1 col-3">
                  <v-select
                      dense
                      outlined
                      label="Existe restrição quanto ao tipo de veículo?"
                      item-text="nome"
                      item-value="id"
                      v-model="form.tipoVeiculoExigido"
                      :items="tipoVeiculoOptions"
                  />
                </v-col>
                <v-col class="col-2">
                  <v-switch
                      label="Necessita de seguro?"
                      v-model="form.seguro"
                      outlined
                      dense
                      small-chips
                      show-size/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                      v-model="form.observacao"
                      outlined
                      :label="form.utilizacaoExterna ? 'Justificativa' : 'Observações'"
                      :rules="observacaoRule"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="col-12">
          <!-- Barra de Ações -->
          <v-card class="mb-5">
            <v-card-text>
              <v-row class="p-2" justify="end">
                <v-btn
                    depressed
                    color="primary"
                    @click="salvar"
                >
                  Solicitar
                </v-btn>
              </v-row>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: function () {
    return {
      // Dados da Solicitação
      form: {
        empresaId: null,
        producaoId: null,
        producao: null,
        solicitante: null,
        origemId: null,
        destinoId: null,
        tipoProduto: null,
        tipoProdutoOutro: null,
        altura: null,
        largura: null,
        comprimento: null,
        pesoUnitario: null,
        unidadeMedida: null,
        quantidade: null,
        numeroNF: null,
        valorNF: null,
        ordemCompra: null,
        tipoVeiculoExigido: null,
        seguro: false,
        contratacaoDireta: false,
        valorFreteFornecedor: null,
        custoEmpresaId: null,
        custoProducaoId: null,
        dataMaximaEntrega: null,
        observacao: '',
      },
      formOrigem: {
        descricao: null,
        cep: null,
        logradouro: null,
        numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        uf: null,
        coordenadas: {},
      },
      formDestino: {
        descricao: null,
        cep: null,
        logradouro: null,
        numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        uf: null,
        coordenadas: {},
      },
      errors: {
        origemUrlGoogle: false,
        destinoUrlGoogle: false,
      },
      tipoProdutoOptions: [
        { id: 0, nome: 'Fertilizantes' },
        { id: 1, nome: 'Equipamentos' },
        { id: 2, nome: 'Peças' },
        { id: 3, nome: 'Outros, Qual?' },
      ],
      unidadeMedidaOptions: [
        { id: 'BG', nome: 'Bag' },
        { id: 'GR', nome: 'Granel' },
        { id: 'SC', nome: 'Sacaria' },
        { id: 'UN', nome: 'Unidade' },
      ],
      tipoVeiculoOptions: [
        { id: 0, nome: 'Vam/Vuc' },
        { id: 1, nome: 'Toco' },
        { id: 2, nome: 'Truck' },
        { id: 3, nome: 'Carreta' },
      ],
      newOrigem: false,
      newDestino: false,
    }
  },

  computed: {
    ...mapGetters(['currentUserPersonalInfo']),
  },

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Fretes' },
      { title: 'Nova Solicitação', route: 'adiciona-solicitacao' },
    ])

    this.form.solicitante = this.currentUserPersonalInfo.name
  },

  methods: {
    setProducao () {
      this.form.producaoId = this.form.producao?.id
      this.form.destinoId = Number.parseInt(this.form.producao?.endereco_id)
    },
    async salvar () {
      if (!this.$refs.form.validate()) {
        return
      }

      if (this.newOrigem) {
        const formOrigem = {
          descricao: this.formOrigem.descricao,
          cep: this.formOrigem.cep,
          logradouro: this.formOrigem.logradouro,
          numero: this.formOrigem.numero,
          complemento: this.formOrigem.complemento,
          bairro: this.formOrigem.bairro,
          cidade: this.formOrigem.cidade,
          uf: this.formOrigem.uf,
          googleMapsUrl: this.formOrigem.coordenadas?.origemUrlGoogle,
          latitude: this.$stringFormat.convertToFloatNumber(this.formOrigem.coordenadas?.latitude),
          longitude: this.$stringFormat.convertToFloatNumber(this.formOrigem.coordenadas?.longitude),
        }
        const newOrigemEndereco = await ApiService.post('/fretes/enderecos', formOrigem)

        if (!newOrigemEndereco.data.data?.id) {
          this.$refs.snackbar.show('Erro ao cadastrar origem', '', 'danger', 3000)
          return
        }

        this.form.origemId = newOrigemEndereco.data.data.id
      }

      if (this.newDestino) {
        const formDestino = {
          descricao: this.formDestino.descricao,
          cep: this.formDestino.cep,
          logradouro: this.formDestino.logradouro,
          numero: this.formDestino.numero,
          complemento: this.formDestino.complemento,
          bairro: this.formDestino.bairro,
          cidade: this.formDestino.cidade,
          uf: this.formDestino.uf,
          googleMapsUrl: this.formDestino.coordenadas?.origemUrlGoogle,
          latitude: this.$stringFormat.convertToFloatNumber(this.formDestino.coordenadas?.latitude),
          longitude: this.$stringFormat.convertToFloatNumber(this.formDestino.coordenadas?.longitude),
        }

        const newDestinoEndereco = await ApiService.post('/fretes/enderecos', formDestino)

        if (!newDestinoEndereco.data.data?.id) {
          this.$refs.snackbar.show('Erro ao cadastrar destino', '', 'danger', 3000)
          return
        }

        this.form.destinoId = newDestinoEndereco.data.data.id
      }

      ApiService
        .post('/fretes/solicitacao', this.form)
        .then((response) => {
          this.$refs.snackbar.show('Solicitação enviada com sucesso!', '', 'success', 3000, true)

          setTimeout(() => {
            window.location.replace('/fretes/lista-solicitacao')
          }, 3000)
        })
        .catch((error) => {
          this.$refs.snackbar.show('Solicitação não enviada!', error.response.data.message, 'danger', 5000, true)
        })
    },
  },
}
</script>
